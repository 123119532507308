import React from 'react';
import Slider from 'react-slick';
import './Home.css'; 
import photo1 from './Photos/Scenery.jpg';
import photo2 from './Photos/Scenery2.jpg';
import photo3 from './Photos/Scenery3.jpg';
import Logo from './Photos/Logo.jpg';

// Placeholder images for service graphics (replace with actual paths)
import preliminary_Ecological_Appraisal from './Photos/Preliminary Ecological Appraisal.jpg';
import ecological_Impact_Assessment from './Photos/Ecological Impact Assessment.jpg';
import mitigation_supervision from './Photos/Mitigation supervision.jpg';
import ecological_Clerk_of_Works from './Photos/Ecological Clerk of Works.jpg';
import protected_Species_Licence_Applications from './Photos/Protected Species Licence Applications.jpg';
import iNNS_surveys from './Photos/INNS surveys.jpg';

const Home = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        appendDots: dots => (
            <div
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <ul style={{ margin: '0px', padding: '0px', listStyle: 'none' }}> {dots} </ul>
            </div>
        ),
    };

    return (
        <div className="home-container">
            <div className="navbar">
                <div className="navbar-logo">
                    <img src={Logo} alt="Ecology Logo" />
                </div>
                <ul className="navbar-menu">
                    <li>Services</li>
                    <li>
                      Protected Species Surveys
                        <ul>
                            <li>Bats</li>
                            <li>Great Crested Newts</li>
                            <li>Badgers</li>
                            <li>Nesting Birds</li>
                            <li>Reptiles</li>
                        </ul>
                    </li>
                    <li>Invasive Non-Native Species</li>
                    <li>About Us</li>
                    <li>Contact Us</li>
                </ul>
                <button className="quote-button">Free Quote</button>
            </div>
            <Slider {...settings} className="slider">
                <div className="slider-item">
                    <img src={photo1} alt="Scenery 1" className="slider-image" />
                </div>
                <div className="slider-item">
                    <img src={photo2} alt="Scenery 2" className="slider-image" />
                </div>
                <div className="slider-item">
                    <img src={photo3} alt="Scenery 3" className="slider-image" />
                </div>
            </Slider>
            <div className="banner-content">
                <h1>A Leading Ecology Consultancy in the UK</h1>
                <p>Energy, Innovation, Results</p>
                <div className="banner-buttons">
                    <button className="enquire-button">Enquire Now</button>
                    <button className="services-button">Our Services</button>
                </div>
            </div>

            {/* New Section for Ecology Services */}
            <div className="services-section">
                <h2>Our Ecology Services</h2>
                <p>We work with you to ensure you meet your objectives, supported by a team of experienced staff to navigate you through your project.</p>
                <p>All protected species surveys can be provided including bats, great crested newts, dormice, reptiles, water voles and badgers. We can also offer detailed habitat surveys.</p>
                <div className="services-list">
                    <div className="service-item">
                        <img src={preliminary_Ecological_Appraisal} alt="preliminary_Ecological_Appraisal" />
                        <p>Preliminary Ecological Appraisal</p>
                    </div>
                    <div className="service-item">
                        <img src={ecological_Impact_Assessment} alt="ecological_Impact_Assessment" />
                        <p>Ecological Impact Assessment</p>
                    </div>
                    <div className="service-item">
                        <img src={mitigation_supervision} alt="mitigation_supervision" />
                        <p>Mitigation supervision</p>
                    </div>
                    <div className="service-item">
                        <img src={ecological_Clerk_of_Works} alt="ecological_Clerk_of_Works" />
                        <p>Ecological Clerk of Works (ECoW)</p>
                    </div>
                    <div className="service-item">
                        <img src={protected_Species_Licence_Applications} alt="protected_Species_Licence_Applications" />
                        <p className="smaller-text">Protected Species Licence Applications</p>
                    </div>
                    <div className="service-item">
                        <img src={iNNS_surveys} alt="iNNS_surveys" />
                        <p>INNS surveys</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
